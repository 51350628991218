@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rubik&display=swap');


@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
    .section-min-height {
        min-height: calc(100vh - 68px)
    } 
}